import {
  Button,
  Container,
  Typography,
  Grid,
  makeStyles,
  Paper,
} from '@material-ui/core'
import React from 'react'
import Layout from '../components/layout'
import NewHeader from '../components/NewHeader'
import MembershipImage from '../images/membership.jpg'
import SEO from '../components/seo'
import '../components/layout.css'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  titleFont: {
    fontFamily: 'Volkhov',
  },
  mt24: {
    marginTop: 24,
  },
  mb24: {
    marginBottom: 24,
  },
  marginMainTop: { marginTop: -120 },
  welfare: {
    background: '#f12711',
    background: '-webkit-linear-gradient(to right, #f5af19, #f12711)',
    background: 'linear-gradient(to right, #f5af19, #f12711)',
  },
  lifetime: {
    background: '#00B4DB',
    background: '-webkit-linear-gradient(to right, #0083B0, #00B4DB)',
    background: 'linear-gradient(to right, #0083B0, #00B4DB)',
  },
  white: {
    color: '#fff',
  },
}))
const Membership = props => {
  const classes = useStyles()

  return (
    <div>
      <Layout>
        <SEO title="Membership" />
        <NewHeader title="Membership" image={MembershipImage} />
        <div className={classes.marginMainTop}>
          <Container>
            <Grid
              container
              spacing={2}
              justify="center"
              alignItems="center"
              direction="row"
            >
              <Grid item md={4} xs={12}>
                <Paper>
                  <div style={{ padding: 64 }} className={classes.welfare}>
                    <Typography
                      variant="h4"
                      className={clsx(classes.white, classes.titleFont)}
                    >
                      Welfare Membership
                    </Typography>
                  </div>
                  <div style={{ padding: 8 }}>
                    <Typography variant="body1">
                      <ul style={{ lineHeight: 2, align: 'left' }}>
                        <li>
                          <Typography variant="body1">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </Typography>
                        </li>
                        <li>
                          Donec faucibus ipsum at dui porttitor fermentum.
                        </li>
                        <li>
                          Aenean congue risus non nibh consectetur, id sagittis
                          felis pharetra.
                        </li>
                        <li>Duis accumsan nisl vitae venenatis commodo.</li>
                        <li>
                          Donec at nisi feugiat, tempor mi nec, ullamcorper
                          velit.
                        </li>
                        <li>Cras iaculis sapien id dictum ullamcorper.</li>
                        <li>
                          Quisque nec ex cursus, condimentum purus nec, iaculis
                          metus.
                        </li>
                        <li>
                          Morbi varius velit id mauris fermentum, vel vehicula
                          ligula volutpat.
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Donec faucibus ipsum at dui porttitor fermentum.
                        </li>
                        <li>
                          Aenean congue risus non nibh consectetur, id sagittis
                          felis pharetra.
                        </li>
                      </ul>
                    </Typography>
                    <div align="center" className={classes.mb24}>
                      <Button
                        className={classes.mt24}
                        variant="outlined"
                        color="primary"
                      >
                        Register
                      </Button>
                    </div>
                  </div>
                </Paper>
              </Grid>
              <Grid item md={4} xs={12}>
                <Paper>
                  <div style={{ padding: 64 }} className={classes.lifetime}>
                    <Typography
                      variant="h4"
                      className={clsx(classes.white, classes.titleFont)}
                    >
                      Lifetime Membership
                    </Typography>
                  </div>
                  <div style={{ padding: 8 }}>
                    <Typography variant="body1">
                      <ul style={{ lineHeight: 2, align: 'left' }}>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Donec faucibus ipsum at dui porttitor fermentum.
                        </li>
                        <li>
                          Aenean congue risus non nibh consectetur, id sagittis
                          felis pharetra.
                        </li>
                        <li>Duis accumsan nisl vitae venenatis commodo.</li>
                        <li>
                          Donec at nisi feugiat, tempor mi nec, ullamcorper
                          velit.
                        </li>
                        <li>Cras iaculis sapien id dictum ullamcorper.</li>
                        <li>
                          Quisque nec ex cursus, condimentum purus nec, iaculis
                          metus.
                        </li>
                        <li>
                          Morbi varius velit id mauris fermentum, vel vehicula
                          ligula volutpat.
                        </li>
                        <li>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit.
                        </li>
                        <li>
                          Donec faucibus ipsum at dui porttitor fermentum.
                        </li>
                        <li>
                          Aenean congue risus non nibh consectetur, id sagittis
                          felis pharetra.
                        </li>
                      </ul>
                    </Typography>
                    <div align="center" className={classes.mb24}>
                      <Button
                        className={classes.mt24}
                        variant="outlined"
                        color="primary"
                      >
                        Register
                      </Button>
                    </div>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div style={{ marginTop: 84 }}></div>
      </Layout>
    </div>
  )
}

export default Membership
